<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 일별 업무일지 결과 목록 -->
          <c-table
            ref="table"
            title= "일별 업무일지 결과 목록"
            gridHeight="750px"
            :columns="grid.columns"
            :isExcelDown="false"
            :data="grid.data"
            :editable="editable&&popupParam.safetyCheck.checkStatusCd !== 'MCSC000015'"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
          >
            <template slot="suffixTitle">
              <font v-if="!popupParam.safetyCheck.plantCd || !popupParam.safetyCheck.checkDate || !popupParam.safetyCheck.checkTypeCd" color="#C10015">
                {{'※ 사업장, 구분 및 점검년월을 선택하세요.'}}
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="editable&&!disabled"
                  :isImmShow="true"
                  :requestContentsCols="requestContentsCols"
                  :tableKeys="tableKeys"
                  ibmTaskTypeCd="ITT0000075"
                  ibmTaskUnderTypeCd="ITTU000130"
                  @imprChange="imprChange"
                />
              </template>
              <template v-else-if="col.name === popupParam.col.key">
                <c-select
                  stype="tableselect"
                  :editable="editable"
                  :comboItems="checkboxItems"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  label=""
                  name="checkItems"
                  v-model="props.row[col.name]"
                  @datachange="datachange(props)"
                >
                </c-select>
              </template>
            </template>
          <template slot="table-header-append">
            <!-- 점검자 -->
            <div style="padding-top: 7px;font-size: 1.1em;font-weight: 600;">{{$label('LBL0002217')}} </div>
            <c-field
              :editable="editable"
              style="min-width: 150px;max-width: 250px;padding-bottom: 0px !important"
              name="checkUserId"
              :placeholder="$label('LBL0002218')"
              v-model="checkUserId">
            </c-field>
          </template>
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 저장 -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="popupParam.safetyCheck.safetyCheckId ? 
                  { 
                    safetyCheckResultModelList: popupParam.safetyCheck.safetyCheckResultModelList,
                    safetyCheckUserModel: {
                      dayId: this.popupParam.col.key,
                      safetyCheckId: this.popupParam.safetyCheck.safetyCheckId, 
                      checkUserId: this.checkUserId
                    },
                  } : this.popupParam.safetyCheck"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyCheckChecking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        col: {},
        equipmentList: [],
        safetyCheck: {
          safetyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkDate: '',  // 점검월
          deteriorName: '',  // 점검명
          safetyCheckResultModelList: [], // sheet
          safetyCheckUserModelList: [],
        }
      }),
    },
    returnData: {
      type: Object,
      default: () => ({
        col1: null,
        col2: null,
        col3: null,
      }),
    }
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      checkboxItems: [],
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      checkUserId: '',
      popupOptions: {
        isFull: false,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.popupParam.safetyCheck.checkStatusCd === 'MCSC000015' 
        || this.popupParam.safetyCheck.approvalStatusCd === 'ASC0000001'
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['safetyCheckResultId','colkey'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/sop/mim/inspectionTableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      let idx = this.$_.findIndex(this.popupParam.safetyCheck.safetyCheckUserModelList, { dayId: this.popupParam.col.key });
      if (idx !== -1) {
        this.checkUserId = this.popupParam.safetyCheck.safetyCheckUserModelList[idx].checkUserId;
      } else {
        this.checkUserId = this.$store.getters.user.userId;
      }

      this.$_.forEach(this.popupParam.safetyCheck.safetyCheckResultModelList, _item => {
        _item.colkey = this.popupParam.col.key;
        this.grid.data.push(_item);
      })

      this.$comm.getComboItems('MIN_SAFETY_RESULT_CD').then(_result => {
        this.checkboxItems = _result;
        let _columns = [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 점검항목
            label: 'LBL0002210',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'checkItemCycleName',
            field: 'checkItemCycleName',
            // 주기
            label: 'LBL0002211',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ]
        let days = [];
        days.push(
          {
            name: this.popupParam.col.key,
            field: this.popupParam.col.key,
            label: this.popupParam.col.label + '일',
            align: 'center',
            setHeader: true,
            comboItems: _result,
            style: 'width:100px',
            type: 'custom',
            headType: 'select',
            sortable: false,
          // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
          },
        )
        this.grid.columns = this.$_.concat(_columns, days, 
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0002219',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:350px',
                type: 'custom',
                sortable: false
              },
            ]
          },)
      });
    },
    research() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.safetyCheck.safetyCheckId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
      this.grid.data= [];
      this.$_.forEach(_result.data.safetyCheckResultModelList, _item => {
        _item.colkey = this.popupParam.col.key;
        this.grid.data.push(_item);
      })
      },);
    },
    saveData() {
      if (this.popupParam.safetyCheck.safetyCheckId) {
        this.saveUrl = transactionConfig.sop.safetycheck.result.newItem.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
        this.mappingType = 'POST';
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.popupParam.safetyCheck.regUserId = this.$store.getters.user.userId;
              this.popupParam.safetyCheck.chgUserId = this.$store.getters.user.userId;
              
              this.popupParam.safetyCheck.safetyCheckResultModelList = this.$_.cloneDeep(this.grid.data);

              if (this.mappingType === 'POST') {
                if (!this.popupParam.safetyCheck.safetyCheckUserModelList) this.popupParam.safetyCheck.safetyCheckUserModelList = []
                this.popupParam.safetyCheck.safetyCheckUserModelList.push({
                  dayId: this.popupParam.col.key,
                  safetyCheckId: this.popupParam.safetyCheck.safetyCheckId, 
                  checkUserId: this.checkUserId,
                  editFlag: 'C',
                })
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.popupParam.safetyCheck.safetyCheckId = result.data
        this.returnData.col1 = result.data
        this.research();
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.research();
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>

<style lang="sass">
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important
.custom-field
  .q-field__native
    cursor: pointer
.q-field--dense .q-field__inner
  padding-bottom: 0px !important
.cardselectarea > div > .customDate
  padding-bottom: 0px !important

  </style>
